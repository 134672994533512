import {
    LimitErrors,
    PERMISSION_RESOURCE_TYPE,
    PresentationFilters,
} from "common/constants";
import { incUserProps } from "js/analytics";
import { ds } from "js/core/models/dataService";
import { FeatureType } from "js/core/models/planPermissions";
import uniqueName from "js/core/utilities/uniqueName";
import { trackActivity } from "js/core/utilities/utilities";
import { CreatePresentationDialogPaneType } from "js/editor/CreatePresentation/CreatePresentationDialogTypes";
import { app } from "js/namespaces";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { _ } from "js/vendor";
import React from "react";

import AppController from "js/core/AppController";
import Api from "js/core/api";
import getLogger from "js/core/logger";
import { Presentation, PresentationPrivacyType } from "js/core/models/presentation";
import { SharedTheme } from "js/core/models/sharedTheme";
import pusher from "js/core/services/pusher";
import getUserProfile from "js/core/services/userProfiles";
import ErrorHandler from "js/core/utilities/errorHandler";
import * as gDrive from "js/core/utilities/gDrive";
import isConnected from "js/core/utilities/isConnected";
import {
    ShowConfirmationDialog, ShowDialog, ShowOfflineDialog, ShowSnackBar, ShowUpgradeDialog, ShowWarningDialog, ShowErrorDialog
} from "js/react/components/Dialogs/BaseDialog";
import InputConfirmationDialog from "js/react/components/Dialogs/InputConfirmationDialog";
import { TeamFoldersController } from "js/core/dataServices/TeamFoldersDataService";

const logger = getLogger();

export class PresentationLibraryDataService {
    constructor(config) {
        ds.presentations.on("change", config.onPresentationsChanged);
        ds.presentations.on("update", config.onPresentationsUpdated);
        ds.userFolders.on("add remove update change", config.onUserFoldersChanged);

        config.onPresentationsChanged(ds.presentations);
        config.onUserFoldersChanged(ds.userFolders);
        this.config = config;
    }

    loadPresentationModels() {
        return ds.presentations.loadModels();
    }

    unsubscribe() {
        ds.presentations.off(null, this.config.onPresentationsChanged);
        ds.presentations.off(null, this.config.onPresentationsUpdated);
        ds.userFolders.off(null, this.config.onUserFoldersChanged);
    }
}

export class PresentationLibraryController {
    static async createPresentation({ documentType, theme, libraryFilter, defaultSlides, name, metadata = {} }) {
        const isSharedTeamTheme = theme instanceof SharedTheme;

        if (!name) {
            // generate a name for the new presentation
            let num = _.max(_.map(ds.presentations.filter(p => {
                return p.has("name") && p.get("name").startsWith("Untitled");
            }), p => {
                if (p.get("name") == "Untitled") {
                    return 1;
                } else {
                    return parseInt(p.get("name").substr("Untitled #".length));
                }
            }));

            name = "Untitled";
            if (num) {
                name += " #" + (num + 1);
            }
        }

        // Increment new presentations by 1.
        incUserProps({
            created_presentations: 1
        });

        // create slides
        if (!defaultSlides) {
            switch (documentType) {
                case "document":
                    defaultSlides = [{
                        templateId: "docContainerElement"
                    }];
                    break;
                case "presentation":
                default:
                    defaultSlides = [{
                        templateId: "title"
                    }];
            }
        }

        // create the presentation
        return ds.presentations.createPresentation({
            name: name,
            // type: documentType, // Commenting this out as it caused an issue with Team Templates: BA-11365
            themeId: isSharedTeamTheme ? null : theme.id,
            sharedThemeId: isSharedTeamTheme ? theme.get("id") : null,
            orgId: AppController.orgId,
            metadata
        }, defaultSlides).then(async presentation => {
            const newSlideCount = Object.keys(presentation.get("slideRefs")).length;
            const eventProps = {
                "presentation_id": presentation.id,
                "current_slide_count": ds.presentations.getWorkspaceSlideCount(presentation.getWorkspaceId(), newSlideCount),
                "slides_created": newSlideCount,
                "workspace_id": presentation.getWorkspaceId()
            };
            trackActivity("Presentation", "New", null, null, eventProps, { audit: true });

            if (!isSharedTeamTheme) {
                app.themeManager.saveThemeToPresentation(theme, presentation, { internalChange: true });
            }

            if (libraryFilter && libraryFilter.type == PresentationFilters.TEAM_FOLDER) {
                if (libraryFilter.subFolderId) {
                    await TeamFoldersController.addPresentationsToTeamSubFolder(
                        [presentation],
                        libraryFilter.folderId,
                        libraryFilter.subFolderId
                    );
                }

                await TeamFoldersController.addPresentationsToTeamFolder([presentation], libraryFilter.folderId, libraryFilter.permission);
            }

            const isNewPresentationCreatedInFolder = libraryFilter && libraryFilter.type == PresentationFilters.FOLDER;
            if (isNewPresentationCreatedInFolder) {
                ds.userFolders.get(libraryFilter.folderId).addPresentationToFolder(presentation.id);
            }

            if (app.user.get("isGDriveEnabled")) {
                // Checking if there's gDriveFolderId in the URL
                const gDriveFolderId = new URL(window.location).searchParams.get("gDriveFolderId") || null;
                gDrive.savePresentation(presentation.id, gDriveFolderId)
                    .catch(err => {
                        logger.error(err, "PresentationLibraryController gDrive.savePresentation() failed", { presentationId: presentation.id, gDriveFolderId });
                    });
            }

            return presentation;
        });
    }

    static handleCreateNewPresentation = ({
        pane = CreatePresentationDialogPaneType.BLANK_PRESENTATION,
        libraryFilter = {},
    }) => {
        if (app.integrator) {
            // Open a new tab to the desktop presentation list
            window.open("/", "_blank");
        } else {
            //offline currently not supported as it just errors out when you try to create a presentation. Offline could fail at this point
            if (!isConnected.connected) {
                ShowOfflineDialog();
                return;
            }

            AppController.showCreatePresentationDialog({
                pane, state: {
                    libraryFilter
                }
            });
        }
    }

    static async createPresentationFromTemplate({ template, libraryFilter }) {
        let templatePresentation = ds.presentations.get(template.id);
        let presentationId = await PresentationLibraryController.duplicatePresentation(templatePresentation, libraryFilter);

        let presentation = new Presentation({ id: presentationId });
        await presentation.load();
        presentation.update({ isTemplate: false });

        return presentation;
    }

    static editPresentation = ({
        presentation,
        openInNewTab = false,
        withAppcue = null,
    }) => {
        switch (presentation.get("type")) {
            case "document":
                AppController.showDocumentEditor({ presentationId: presentation.id, openInNewTab });
                break;
            default:
                AppController.showEditor({ presentationId: presentation.id, openInNewTab, withAppcue });
        }
    }

    static playPresentation = async presentation => {
        const entry_slide_index = 0;
        trackActivity("Presentation", "Present");

        const props = {
            initiated_via: "library",
            entry_slide_index: entry_slide_index + 1,
            presentation_id: presentation.id,
            presentation_slide_count: presentation.getSlideCount(),
            current_slide_count: ds.presentations.getWorkspaceSlideCount(presentation.getWorkspaceId()),
            workspace_id: presentation.getWorkspaceId()
        };
        trackActivity("Presentation", "PresentOpen", null, null, props, { audit: true });

        if (window.roomID) {
            if (!presentation.get("public")) {
                if (await ShowConfirmationDialog({
                    title: "Do you want to set the presentation to public?",
                    message: "Your presentation is set to private but must be public in order to launch using remote control.",
                    okButtonLabel: "Set to public"
                })) {
                    await presentation.setPrivacySetting(PresentationPrivacyType.PUBLIC);
                }
            }
        }

        if (app.integrator) {
            app.integrator.registerPresentation(presentation);
        } else if (app.isMobileOrTablet) {
            // To load the player UI to be compatible for mobile we need to switch the location to the player url instead
            // of playing the presentation from app.mainView.playPresentation
            let url = `/player/${presentation.id}`;
            if (window.roomID) {
                url += `?roomID=${window.roomID}&remoteRole=leader`;

                // for efficiency, trigger the registerLeader event on the pusher channel so that the clients can start loading their players immediately
                // instead of needing to wait for our player redirect to load
                const remoteSetupChannel = await pusher.subscribe(`presence-remote-control-${window.roomID}`);
                remoteSetupChannel.trigger("client-registerLeader", { presentationId: presentation.id });
            }
            window.location.href = url;
        } else {
            if (window.roomID) {
                window.remoteRole = "leader";
            }
            AppController.playPresentation({ presentationId: presentation.id, slideIndex: entry_slide_index });
        }
    }

    static renamePresentation = (presentation, value) => {
        if (value.trim() == "") {
            throw new Error("Please enter a name for this presentation.");
        }
        const currentPresentationName = presentation.get("name");
        if (value !== currentPresentationName) {
            const eventProps = {
                from_name: currentPresentationName,
                to_name: value,
                presentation_id: presentation.get("id"),
                workspace_id: presentation.getWorkspaceId()
            };
            trackActivity("Presentation", "Rename", null, null, eventProps, { audit: true });
        }
        presentation.update({ name: value });
        presentation.updatePromise.catch(error => {
            ShowErrorDialog({
                title: "We were unable to rename your presentation.",
                message: <>
                    {error.status === 409 && <span>
                        Oops! Another user made changes to this presentation at the same time you did. We’ve loaded the most recent version for you to edit.<br />
                        If the problem persists, please contact <a href="mailto:support@beautiful.ai">support@beautiful.ai</a>
                    </span>}
                    {error.status !== 409 && <span>
                        There may be a temporary problem with your internet connection.<br />
                        If the problem persists, please contact <a href="mailto:support@beautiful.ai">support@beautiful.ai</a>
                    </span>}
                </>,
            });
        });
    }

    static getCurrentFolderId = filter => {
        if (filter.type === PresentationFilters.FOLDER) {
            return filter.folderId;
        } else if (filter.type === PresentationFilters.TEAM_FOLDER) {
            return filter.teamId;
        }

        return null;
    }

    static duplicatePresentation = async (presentation, filter, openEditor = true) => {
        const names = ds.presentations.map(presentation => {
            return presentation.get("name");
        });

        const name = uniqueName(presentation.get("name"), names);
        return presentation.duplicate(name).then(response => {
            const { presentationId } = response;

            incUserProps({
                created_presentations: 1
            });

            if (filter && filter.folderId) {
                ds.userFolders.get(filter.folderId)?.addPresentationToFolder(presentationId);
            }
            const duplicatedSlideCount = Object.keys(presentation.get("slideRefs") || {}).length;

            const eventProps = {
                "presentation_id": presentationId,
                "source_presentation": presentation.id,
                "current_slide_count": ds.presentations.getWorkspaceSlideCount(presentation.getWorkspaceId(), duplicatedSlideCount),
                "slides_created": duplicatedSlideCount,
                "workspace_id": presentation.getWorkspaceId(),
            };
            trackActivity("Presentation", "Duplicate", null, null, eventProps, { audit: true });

            if (openEditor) {
                return ds.presentations.getPresentation(presentationId).then(createdPresentation => {
                    PresentationLibraryController.editPresentation({ presentation: createdPresentation });
                    return presentationId;
                });
            }

            return presentationId;
        }).catch(err => {
            if (err.message === LimitErrors.SLIDE_COUNT_LIMIT_REACHED) {
                const workspaceId = presentation.getWorkspaceId();
                ShowUpgradeDialog({
                    type: UpgradePlanDialogType.EXCEEDED_SLIDE_LIMIT,
                    analytics: {
                        "cta": "Slide Limit Reached",
                        ...presentation.getAnalytics()
                    },
                    workspaceId
                });
                return;
            } else {
                throw new Error(err);
            }
        });
    }

    static async trashPresentations(presentations, filter) {
        const presentationsOwnedByCurrentUser = presentations.filter(p => p.permissions.owner);
        if (presentationsOwnedByCurrentUser.length !== presentations.length) {
            ShowWarningDialog({
                title: "Oops!",
                message: "Presentations that are being collaborated on can not move to trash!",
            });
        }
        const presentationIds = presentationsOwnedByCurrentUser.map(p => p.id);
        try {
            await Api.userPermissions.put({ permissionIds: presentationIds });
        } catch (err) {
            logger.error(err, "PresentationLibraryController Api.userPermissions.put() failed", { presentationIds });
            throw (err);
        }
        if (app.user.has("teams")) {
            try {
                for (let presentationId of presentationIds) {
                    ds.teams.forEach(team => {
                        if (team.has("sharedResources") &&
                            team.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION] &&
                            Boolean(team.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION][presentationId])) {
                            // remove all of the presentations from being shared with any teams
                            team.update({ "sharedResources": { [PERMISSION_RESOURCE_TYPE.PRESENTATION]: { [presentationId]: null } } });
                        }
                    });
                }
            } catch (err) {
                logger.error(err, "PresentationLibraryController ds.teams.forEach() failed", { presentationIds });
            }
        }
        presentationsOwnedByCurrentUser.forEach(presentation => {
            if (presentation.permissions.owner) {
                ds.userFolders.forEach(folderModel => {
                    const folderPresentationIds = folderModel.get("presentations");
                    if (folderPresentationIds && folderPresentationIds.length && folderPresentationIds.contains(presentation.id)) {
                        folderModel.removePresentationFromFolder(presentation.id);
                    }
                });

                if (presentation.permissions.owner) {
                    if (app.user.get("isGDriveEnabled")) {
                        gDrive.removePresentation(presentation.id)
                            .catch(err => {
                                logger.error(err, "PresentationLibraryController gDrive.removePresentation() failed", { presentationId: presentation.id });
                            });
                    }

                    presentation.update({ softDeletedAt: new Date().getTime() });

                    const eventProps = {
                        "presentation_id": presentation.id,
                        "workspace_id": presentation.getWorkspaceId(),
                        "library_location": filter.type,
                        "current_folder_id": PresentationLibraryController.getCurrentFolderId(filter)
                    };
                    trackActivity("Presentation", "MoveToTrash", null, null, eventProps, { audit: true });
                    presentation.setPrivacySetting(PresentationPrivacyType.PRIVATE);
                }
            }
        });
    }

    static emptyTrashNow() {
        const softDeletedPresentations = ds.presentations.filter(presentation => presentation.get("softDeletedAt"));
        if (softDeletedPresentations.length) {
            ShowConfirmationDialog({
                title: "Are you sure you want to permanently delete all of the presentations in the trash?",
                message: "You can't undo this action.",
                acceptCallback: () => {
                    ds.presentations.emptyTrash(AppController.workspaceId);
                }
            });
        }
    }

    static deletePresentations(presentations) {
        for (let presentation of presentations) {
            const isOwner = presentation.permissions.owner;
            const destroyedId = presentation.id;
            const deletedSlideCount = -Object.keys(presentation.get("slideRefs") || {}).length;
            const workspaceId = presentation.getWorkspaceId();
            presentation.destroy(workspaceId);
            if (isOwner) {
                incUserProps({
                    deleted_presentations: 1
                });
            }
            const eventProps = {
                "presentation_id": destroyedId,
                "library_location": PresentationFilters.TRASH,
                "current_slide_count": ds.presentations.getWorkspaceSlideCount(workspaceId),
                "slides_created": deletedSlideCount,
            };
            trackActivity("Presentation", "Delete", null, null, eventProps, { audit: true });
        }
    }

    static recoverPresentations(presentations) {
        for (let presentation of presentations) {
            if (presentation.get("softDeletedAt")) {
                presentation.recoverPresentation()
                    .then(() => {
                        const eventProps = {
                            "presentation_id": presentation.id,
                            "library_location": PresentationFilters.TRASH,
                        };
                        trackActivity("Presentation", "Recovered", null, null, eventProps, { audit: true });
                        if (app.user.get("isGDriveEnabled")) {
                            gDrive.savePresentation(presentation.id)
                                .catch(err => {
                                    logger.error(err, "PresentationLibraryController gDrive.savePresentation() failed", { presentationId: presentation.id });
                                });
                        }
                    })
                    .catch(err => ErrorHandler.handleSlideLimitReached(err, { workspaceId: presentation.getWorkspaceId() }));
            }
        }
    }

    static createUserFolder = () => {
        const workspaceId = AppController.workspaceId;
        if (!app.user.features.isFeatureEnabled(FeatureType.FOLDERS, workspaceId)) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                analytics: { cta: "Folder" },
                workspaceId
            });
            return;
        }

        if (ds.userFolders.length > 49) {
            ShowWarningDialog({
                title: "Oops!",
                message: "You have reached the folder limit!",
            });
            return;
        }
        ShowDialog(InputConfirmationDialog, {
            title: "Create Folder",
            input: {
                type: "text",
                placeholder: "Type folder name...",
                maxLength: "50",
                id: "create_folder_input",
                inputProps: {
                    maxLength: 50
                }
            },
            acceptCallback: async value => {
                let folderName = value;
                const workspaceId = AppController.workspaceId;
                const orgId = workspaceId == "personal" ? undefined : workspaceId;

                if (folderName.length && !ds.userFolders.find({ name: folderName })) {
                    // StorageModel turns null -> undefined, but Backbone doesn't *and* Firebase
                    // doesn't accept undefined field values, so we have to remove the orgId field
                    // entirely instead of setting it to undefined or null
                    const optionalOrgId = orgId === undefined ? {} : { orgId };
                    let folder = await ds.userFolders.createFolder({ name: folderName, ...optionalOrgId });
                    let folder_id = folder.id;

                    trackActivity("Library", "NewFolder", null, null, { folder_id }, { audit: false });
                } else if (folderName.length) {
                    ShowWarningDialog({
                        title: "Unable to create folder",
                        message: "Please enter a unique folder name.",
                    });
                } else {
                    ShowWarningDialog({
                        title: "Unable to create folder",
                        message: "Please enter a folder name.",
                    });
                }
            }
        });
    }

    static addPresentationsToFolder = (presentations, folderId, callback) => {
        //Recover any presentations that are in the trash before adding them to a folder.
        PresentationLibraryController.recoverPresentations(presentations);

        let selectedPresentationIdsObj = {};
        presentations.forEach(selectedPresentation => {
            selectedPresentationIdsObj[selectedPresentation.id] = 1;
        });

        //Logic to remove presentation(s) from existing folders
        ds.userFolders.forEach(folderModel => {
            const folderPresentationIds = folderModel.get("presentations");
            if (folderPresentationIds && folderPresentationIds.length) {
                folderPresentationIds.forEach(folderPresentationId => {
                    if (selectedPresentationIdsObj[folderPresentationId] && folderModel.id !== folderId) {
                        folderModel.removePresentationFromFolder(folderPresentationId);
                    }
                });
            }
        });

        let folder = ds.userFolders.get(folderId);

        //Add presentation(s) to target folder.
        const folderPresentationIds = folder.get("presentations");

        let movedPresentationCount = 0;
        for (let selectedPresentationId in selectedPresentationIdsObj) {
            let presentationExistsInFolder = false;
            if (folderPresentationIds && folderPresentationIds.length) {
                presentationExistsInFolder = !!folderPresentationIds.find(presentationId => presentationId === selectedPresentationId);
            }
            if (!presentationExistsInFolder) {
                folder.addPresentationToFolder(selectedPresentationId);
                movedPresentationCount++;
            }
        }

        if (movedPresentationCount > 0) {
            let snackbar;
            const action = () => {
                callback();
                snackbar.close();
            };

            const message = (
                <p>
                    <span>{movedPresentationCount} {(movedPresentationCount > 1) ? " presentations were added to " : " presentation was added to "}</span>
                    <span className="action_item" onClick={action}>{folder.get("name")}</span>
                </p>
            );
            snackbar = ShowSnackBar({
                message,
            });
        } else {
            throw new Error("The selected presentations are already in this folder.");
        }
    }

    static removePresentationFromFolder = (presentation, folderId) => {
        const folder = ds.userFolders.get(folderId);
        folder.removePresentationFromFolder(presentation.id);
    }

    static renameFolder = (folderId, folderName) => {
        let folder = ds.userFolders.get(folderId);
        if (!folder) throw new Error("Folder not found!");

        if (folderName.length && !ds.userFolders.find({ name: folderName })) {
            folder.update({ name: folderName });
        } else {
            throw new Error("Please enter a unique folder name.");
        }
    }

    static deleteUserFolder = folderId => {
        let folder = ds.userFolders.get(folderId);
        if (!folder) throw new Error("Folder not found!");

        if (!folder.get("presentations") || !folder.get("presentations").length) {
            folder.destroy();
        } else {
            throw new Error("This folder contains presentations and can't be deleted.");
        }
    }

    static getFilteredPresentations = async (allPresentations, options) => {
        let workspaceId = AppController.workspaceId;

        let presentations = allPresentations.filter(presentation =>
            presentation.getWorkspaceId() === workspaceId &&
            presentation.get("isTemplate") != true &&
            presentation.disconnected == false
        );

        presentations = await Promise.all(presentations.map(p => {
            return getUserProfile(p.get("userId"))
                .then(result => result.displayName || result.email)
                .then(ownerName => {
                    return {
                        presentationModel: p,
                        id: p.id,
                        name: p.get("name"),
                        type: p.get("type"),
                        createdAt: p.get("createdAt"),
                        modifiedAt: p.get("modifiedAt"),
                        firstSlideModifiedAt: p.get("firstSlideModifiedAt"),
                        userId: p.get("userId"),
                        slideCount: Object.keys(p.get("slideRefs") || {}).length,
                        isTrashed: p.get("softDeletedAt") != null,
                        readOnly: !p.permissions.owner && !p.permissions.write,
                        isShared: !p.permissions.owner && p.permissions.read,
                        owner: p.owner,
                        ownerName,
                    };
                });
        }));

        if (options.filter) {
            if (options.filter.type == PresentationFilters.TRASH) {
                presentations = presentations.filter(presentation => presentation.isTrashed);
            } else {
                presentations = presentations.filter(presentation => !presentation.isTrashed);

                switch (options.filter.type) {
                    case PresentationFilters.RECENT:
                        let recentPresentations = app.user.getLibrarySettings().recentPresentations;
                        if (recentPresentations?.length) {
                            presentations = presentations.filter(presentation => recentPresentations.contains(presentation.id));
                        }
                        break;
                    case PresentationFilters.OWNED_BY_ME:
                        presentations = presentations.filter(presentation => presentation.presentationModel.permissions.owner);
                        break;
                    case PresentationFilters.SHARED_WITH_ME:
                        presentations = presentations.filter(presentation => !presentation.presentationModel.permissions.owner && presentation.presentationModel.permissions.read);
                        break;
                    case PresentationFilters.FOLDER:
                        let folderModel = ds.userFolders.get(options.filter.folderId);
                        if (folderModel) {
                            const presentationIdList = folderModel.get("presentations");
                            if (presentationIdList && presentationIdList.length) {
                                presentations = presentations.filter(presentation => presentationIdList.contains(presentation.id));
                            } else {
                                presentations = [];
                            }
                        }
                        break;
                    case PresentationFilters.TEAM_FOLDER:
                        let teamFolder = ds.teams.get(options.filter.folderId);
                        if (teamFolder && teamFolder.get("sharedResources") && teamFolder.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION]) {
                            const presentationIdList = Object.keys(teamFolder.get("sharedResources")[PERMISSION_RESOURCE_TYPE.PRESENTATION]);
                            if (presentationIdList && presentationIdList.length) {
                                presentations = presentations.filter(presentation => presentationIdList.contains(presentation.id));

                                let subFolders = teamFolder.get("subFolders");
                                if (options.filter.subFolderId) {
                                    // filter to just presentations in selected subfolder
                                    let subFolder = subFolders.findById(options.filter.subFolderId) || [];
                                    if (subFolder?.presentations) {
                                        presentations = presentations.filter(presentation => subFolder.presentations.contains(presentation.id));
                                    } else {
                                        presentations = [];
                                    }
                                } else if (subFolders) {
                                    // filter out any presentations in any subfolders
                                    let subFolderPresentations = subFolders.map(sub => sub.presentations).flat();
                                    presentations = presentations.filter(presentation => !subFolderPresentations.contains(presentation.id));
                                }
                            } else {
                                presentations = [];
                            }
                        } else {
                            presentations = [];
                        }
                        break;
                    case PresentationFilters.ALL_PRESENTATIONS:
                    default:
                    // no filter
                }
            }
        }

        if (options.searchResults) {
            // convert array of scores into hash for efficient lookup
            const presentationScores = options.searchResults.reduce((acc, val) => {
                const presentationId = val.presentation_id.raw;
                const score = val._meta.score;
                const currentHigh = acc[presentationId] || 0;
                acc[presentationId] = Math.max(currentHigh, score);
                return acc;
            }, {});

            presentations = presentations.filter(({ id }) => presentationScores[id]);
            presentations.sort((a, b) => presentationScores[b.id] - presentationScores[a.id]);
        }

        // we do not need to sort for "relevance" option, the results are return in order from app search
        if (options.sort && options.sort.field !== "relevance") {
            presentations = _.sortBy(presentations, p => {
                if (typeof p[options.sort.field] == "string") {
                    return p[options.sort.field].toLowerCase();
                } else {
                    return p[options.sort.field];
                }
            });
            if (options.sort.reverse) {
                presentations = presentations.reverse();
            }
        }

        //When a user joins a workspace the TeamGettingStarted Deck should be the first deck
        //and the SharedSlideStartedDeck deck should be the second deck they see.
        if (options.isNewWorkspace) {
            const TeamGettingStartedDecks = "Your Team Plan Guide";
            const SharedSlideStartedDeck = "Shared Slides Starter Pack";

            presentations.sort((firstElement, secondElement) => {
                if (TeamGettingStartedDecks === firstElement.name) {
                    return -1;
                }

                if (TeamGettingStartedDecks === secondElement.name) {
                    return 1;
                }

                if (firstElement.name === SharedSlideStartedDeck && secondElement.name !== SharedSlideStartedDeck) {
                    return -1;
                } else if (firstElement.name !== SharedSlideStartedDeck && secondElement.name === SharedSlideStartedDeck) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }

        // Only show the first 12 presentations for recent view
        if (
            options.filter.type === PresentationFilters.RECENT &&
            presentations.length > 12
        ) {
            presentations.splice(12, Number.POSITIVE_INFINITY);
        }

        return presentations;
    }
}
