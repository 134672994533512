import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

import getLogger, { LogGroup } from "js/core/logger";
import db from "js/db";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { trackActivity } from "js/core/utilities/utilities";
import NotificationsService from "js/core/services/notifications";
import { MenuItem, Select, Icon } from "@material-ui/core";
import { Subscription } from "rxjs";
import classNames from "classnames";
import Api from "js/core/api";
import { Key } from "js/core/utilities/keys";
import AppController from "js/core/AppController";
import { dialogTheme } from "js/react/materialThemeOverrides";
import { FlexBox } from "js/react/components/LayoutGrid";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import Comment from "./Comment";

const logger = getLogger(LogGroup.COMMENTS);

const CommentFilters = {
    AllSlides: "allSlides",
    CurrentSlide: "currentSlide",
};

const styleSelect = theme => ({
    icon: {
        color: "#A9A9A9",
    },
    select: {
        paddingLeft: 0,
        color: "#A9A9A9",
        display: "flex",
        alignItems: "center",
    },
    nativeInput: {
        width: "auto",
    },
});

const CommentFilterSelect = styled(withStyles(styleSelect)(Select))`
    margin-top: 8px;

    .select-light {
        color: #FFF;
    }
`;

const BlueIcon = styled(Icon)`
    color: #11A9E2;
`;

const CommentsList = styled.div`
    overflow-y: auto;
    height: 100%;
    margin-top: 10px;
`;
class CommentsPane extends Component {
    state = {
        collaborators: [],
        teammates: [],
        byViewer: true,
        allComments: [],
        slideComments: [],
        commentFilter: CommentFilters.CurrentSlide,
        canEditPresentation: false,
        assignedUser: null,
        isPresentationOwner: false,
    }

    constructor() {
        super();

        this.user = app.user.getAuthUser();
        this.commentListRef = React.createRef();
        this.subs = new Subscription();
        this.handleGlobalKeyDown = null;
    }

    async componentDidMount() {
        const {
            collaboratorsAndTeammates$,
            areCommentsByViewer$,
            allComments$,
            slideComments$,
            assignedToSlideUser$,
        } = ds.getObservables();
        this.subs.add(
            collaboratorsAndTeammates$.subscribe(this.handleCollaboratorsAndTeammatesUpdate)
        );
        this.subs.add(
            areCommentsByViewer$.subscribe(byViewer => this.setState({ byViewer }))
        );
        this.subs.add(
            allComments$.subscribe(allComments => this.setState({ allComments }))
        );
        this.subs.add(
            slideComments$.subscribe(slideComments => this.setState({ slideComments }))
        );
        this.subs.add(
            assignedToSlideUser$.subscribe(assignedUser => this.setState({ assignedUser }))
        );

        const presentation = ds.selection.presentation;
        const isPresentationOwner = presentation?.get("userId") === this.user.uid;
        let canEditPresentation = isPresentationOwner;
        if (!canEditPresentation) {
            const { permissions } = await Api.permissions.get({ id: presentation.id })
                .catch(() => ({ permissions: [] }));
            const isCollabEditor = permissions.some(perm => perm.id === this.user.uid && perm.type === "edit");
            canEditPresentation = isCollabEditor;
        }

        this.setState({
            canEditPresentation,
            isPresentationOwner
        });

        this.handleGlobalKeyDown = evt => {
            if (evt.which === Key.ESCAPE) {
                evt.preventDefault();
                evt.stopPropagation();
                this.props.close && this.props.close();
            }
        };
        window.addEventListener("keydown", this.handleGlobalKeyDown);

        trackActivity("Comments", "Opened");
    }

    componentWillUnmount() {
        this.subs.unsubscribe();
        window.removeEventListener("keydown", this.handleGlobalKeyDown);

        trackActivity("Comments", "Closed");
    }

    handleCollaboratorsAndTeammatesUpdate = ({ collaborators, teammates }) => {
        this.setState({ collaborators, teammates });
    }

    postComment = async (text, taggedUsers, pendingTaggedUsers) => {
        try {
            const {
                byViewer
            } = this.state;

            const { currentSlide } = this.props;
            const slideId = currentSlide.id;
            const presentationId = ds.selection.presentation.id;

            // Creating a proper signature for the comment to be posted
            const comment = {
                text,
                authorUid: this.user.uid,
                createdAt: moment().valueOf(),
                taggedUsers,
                pendingTaggedUsers,
                byViewer,
            };

            const commentRef = db("comments").child(presentationId).child(slideId).push();
            await commentRef.set(comment);

            // Calling the notifications endpoint to generate notifications for the collaborators
            const commentId = commentRef.key;
            await NotificationsService.notifyOnComment(presentationId, slideId, commentId);

            const props = {
                ...ds.selection.presentation.getAnalytics(),
                slide_id: slideId,
                comment_id: commentId,
                byViewer,
            };
            trackActivity("Comment", "Created", null, null, props, { audit: true });
        } catch (err) {
            logger.error(err, "[comments] postComment() failed");
        }
    }

    removeComment = async comment => {
        try {
            const commentId = comment.id;
            const slideId = comment.slideId;
            const presentationId = ds.selection.presentation.id;

            await db("comments").child(presentationId).child(slideId).child(commentId).set(null);

            const props = {
                ...ds.selection.presentation.getAnalytics(),
                slide_id: slideId,
                comment_id: commentId,
                byViewer: this.state.byViewer,
            };
            trackActivity("Comment", "Deleted", null, null, props, { audit: true });
        } catch (err) {
            logger.error(err, "[comments] removeComment() failed");
        }
    }

    updateComment = async (comment, text, taggedUsers, pendingTaggedUsers) => {
        try {
            const commentId = comment.id;
            const slideId = comment.slideId;
            const presentationId = ds.selection.presentation.id;

            // Will be updating only text and tagged users
            const commentUpdates = {
                text,
                taggedUsers,
                pendingTaggedUsers
            };
            await db("comments").child(presentationId).child(slideId).child(commentId).update(commentUpdates);

            // Calling the notifications endpoint to generate notifications for the collaborators
            // (in case we tagged new users)
            await NotificationsService.notifyOnComment(presentationId, slideId, commentId);

            const props = {
                ...ds.selection.presentation.getAnalytics(),
                slide_id: slideId,
                comment_id: commentId,
                byViewer: this.state.byViewer,
            };
            trackActivity("Comment", "Edited", null, null, props, { audit: true });
        } catch (err) {
            logger.error(err, "[comments] updateComment() failed");
        }
    }

    markCommentAsRead = async comment => {
        try {
            const commentId = comment.id;
            const slideId = comment.slideId;
            const presentationId = ds.selection.presentation.id;

            await db("comments").child(presentationId).child(slideId).child(commentId).child("readBy").update({ [this.user.uid]: moment().valueOf() });

            await AppController.notificationsService.markAsReadByCommentId(commentId);
        } catch (err) {
            logger.error(err, "[comments] markCommentAsRead() failed");
        }
    }

    render() {
        const {
            collaborators,
            teammates,
            byViewer,
            allComments,
            slideComments,
            commentFilter,
            canEditPresentation,
            assignedUser,
            isPresentationOwner
        } = this.state;

        const {
            currentSlide,
            goToSlide,
            transitionState
        } = this.props;

        const presentation = ds.selection.presentation;
        const slideIndex = presentation.getSlideIndex(currentSlide.id);

        let comments = commentFilter === CommentFilters.AllSlides ? allComments : slideComments;

        return (
            <MuiThemeProvider theme={dialogTheme}>
                <FlexBox center middle>
                    <CommentFilterSelect
                        value={commentFilter}
                        onChange={event => this.setState({ commentFilter: event.target.value })}
                        disableUnderline
                    >
                        <MenuItem value={CommentFilters.CurrentSlide}>
                            <BlueIcon>view_carousel</BlueIcon>
                            &nbsp;
                            <span className="select-light">This Slide</span>
                            &nbsp;
                            <span>({slideComments.length})</span>
                        </MenuItem>
                        <MenuItem value={CommentFilters.AllSlides}>
                            <BlueIcon>apps</BlueIcon>
                            &nbsp;
                            <span className="select-light">All Slides</span>
                            &nbsp;
                            <span>({allComments.length})</span>
                        </MenuItem>
                    </CommentFilterSelect>
                </FlexBox>
                <CommentsList
                    ref={this.commentListRef}
                    className={classNames({
                        [`comment-list`]: true,
                        [`viewer`]: byViewer,
                        [`editor`]: !byViewer,
                    })}
                >
                    <Comment
                        isNew
                        key="newComment"
                        post={(text, taggedUsers, pendingTaggedUsers) => {
                            this.postComment(text, taggedUsers, pendingTaggedUsers);
                        }}
                        author={{ ...this.user, displayName: this.user.displayName || this.user.email }}
                        cancelAddingComment={() => this.setState({ isAddingComment: false })}
                        collaborators={collaborators}
                        teammates={teammates}
                        slideIndex={slideIndex}
                        byViewer={byViewer}
                        assignedUser={assignedUser}
                        canEdit={transitionState === "entered"}
                    />
                    {
                        comments.map(comment => (
                            <Comment
                                key={comment.id}
                                isMy={comment.authorUid === this.user.uid}
                                byViewer={comment.byViewer}
                                isPresentationOwner={isPresentationOwner}
                                canEditPresentation={canEditPresentation}
                                remove={() => this.removeComment(comment)}
                                update={(text, taggedUsers, pendingTaggedUsers) => this.updateComment(comment, text, taggedUsers, pendingTaggedUsers)}
                                markAsRead={() => this.markCommentAsRead(comment)}
                                text={comment.text}
                                slideIndex={slideIndex}
                                author={comment.author}
                                isRead={comment.readBy && comment.readBy[this.user.uid]}
                                createdAt={comment.createdAt}
                                assignedUser={assignedUser}
                                collaborators={collaborators}
                                teammates={teammates}
                                goToSlide={slideId => goToSlide(slideId)}
                                canEdit={transitionState === "entered"}
                            />
                        ))
                    }
                </CommentsList>
            </MuiThemeProvider>
        );
    }
}

export default PresentationEditorController.withState(CommentsPane);
