import React from "react";

import getLogger, { LogGroup } from "js/core/logger";
import { getExperiments } from "js/core/services/experiments";

const logger = getLogger(LogGroup.APP);

export class Experiment extends React.Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        const { id } = this.props;

        try {
            const { [id]: { enabled } } = await getExperiments([id]);
            this.setState({ loading: false, enabled });
        } catch (err) {
            // failsafe
            logger.error(err, "[Experiment] componentDidMount() failed");
            this.setState({ loading: false, enabled: false });
        }
    }

    render() {
        if (this.state.loading) {
            return null;
        } else {
            return this.props.children(this.state.enabled);
        }
    }
}
