import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    TextField,
    Button,
    Link,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

import getLogger from "js/core/logger";

const logger = getLogger();

const styles = {
    root: {
        boxSizing: "border-box",
        alignItems: "center",
        position: "relative"
    },
    input: {
        fontSize: 16,
        "& input.MuiOutlinedInput-input": {
            "&::-webkit-contacts-auto-fill-button": {
                visibility: "hidden"
            }
        }
    },
    inputSuccess: {
        fontSize: 16,
        color: "#7fb314",
        WebkitTextFillColor: "#7fb314", // Safari shows disabled fields too bright
        textTransform: "uppercase"
    },
    button: {
        position: "absolute",
        right: 2,
        top: 1
    },
    iconButton: {
        position: "absolute",
        right: 6,
        top: 4
    },
    spinner: {
        position: "absolute",
        right: 14,
        top: 9
    },
    errorText: {
        fontSize: 14,
        marginLeft: 0,
    },
    addPromoButton: {
        color: "#11a9e2",
        height: 18,
        fontFamily: "Source Sans Pro",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        letterSpacing: 1,
        textTransform: "uppercase",
        textAlign: "left",
        paddingLeft: 0,
        marginTop: 18,
        marginBottom: 18
    }
};

class PromoField extends React.Component {
    state = {
        code: "",
        error: null,
        name: null,
        offer: null,
        loading: false,
        showField: false
    }

    componentDidUpdate = prevProps => {
        // if fetch key changed, refetch
        if (this.props.fetchKey != prevProps.fetchKey) {
            this.handleApply();
        }
    }

    handleChange = e => {
        this.setState({
            code: e.target.value,
            error: null
        });
    }

    handleShowField = () => {
        this.setState({ showField: true });
    }

    handleApply = () => {
        if (this.state.code.length > 0) {
            this.setState({
                error: null,
                loading: true
            });

            this.props.fetchPromo(this.state.code)
                .then(({ offer, name }) => {
                    this.setState({
                        name,
                        offer,
                        loading: false
                    });
                }).catch(err => {
                    logger.error(err, "[PromoField] handleApply() failed");
                    this.setState({
                        error: err.message,
                        loading: false
                    });
                });
        }
    }

    handleClear = () => {
        this.setState({
            code: "",
            name: null,
            offer: null,
            error: null
        });
        this.props.onClear();
    }

    render() {
        const { classes, autoFocus, error, isSignUpB } = this.props;
        const state = this.state;
        if (isSignUpB) {
            return (
                <>
                    {!state.showField ? (
                        <Button
                            className={classes.addPromoButton}
                            onClick={this.handleShowField}
                        >
                            ADD PROMO CODE
                        </Button>
                    ) : (
                        <div className={classes.root}>
                            <TextField
                                InputProps={{ className: classes.input }}
                                placeholder="Promo Code"
                                onChange={this.handleChange}
                                value={state.offer ? `${state.name} (${state.offer})` : state.code}
                                fullWidth
                                error={!!state.error || !!error}
                                helperText={state.error || error}
                                disabled={!!state.offer}
                                inputProps={{ className: state.offer ? classes.inputSuccess : "" }}
                                FormHelperTextProps={{ className: classes.errorText }}
                                autoFocus={autoFocus}
                                autoComplete="off"
                            />
                            {!state.offer && !state.loading && (
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    onClick={this.handleApply}
                                >
                                    Apply
                                </Button>
                            )}
                            {state.offer && (
                                <IconButton
                                    size="small"
                                    className={classes.iconButton}
                                    onClick={this.handleClear}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                            {state.loading && (
                                <CircularProgress className={classes.spinner} size={20} />
                            )}
                        </div>
                    )}
                    {state.code.length > 0 && (
                        <h3 style={{ fontSize: 14, marginTop: 10 }}>
                            By clicking apply you agree to the{" "}
                            <Link
                                href="/promotion-terms"
                                target="_blank"
                            >Promotion Terms and Conditions
                            </Link>
                        </h3>
                    )}
                </>
            );
        } else {
            return (
                <>
                    <div className={classes.root}>
                        <TextField
                            InputProps={{ className: classes.input }}
                            variant="outlined"
                            placeholder="Promo Code"
                            onChange={this.handleChange}
                            value={state.offer ? `${state.name} (${state.offer})` : state.code}
                            fullWidth
                            error={!!state.error || !!error}
                            helperText={state.error || error}
                            disabled={!!state.offer}
                            inputProps={{ className: state.offer ? classes.inputSuccess : "" }}
                            FormHelperTextProps={{ className: classes.errorText }}
                            autoFocus={autoFocus}
                            autoComplete="off"
                        />
                        {!state.offer && !state.loading && (
                            <Button
                                className={classes.button}
                                color="primary"
                                onClick={this.handleApply}
                            >
                                Apply
                            </Button>
                        )}
                        {state.offer && (
                            <IconButton
                                size="small"
                                className={classes.iconButton}
                                onClick={this.handleClear}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                        {state.loading && (
                            <CircularProgress className={classes.spinner} size={20} />
                        )}
                    </div>
                    {state.code.length > 0 && (
                        <h3 style={{ fontSize: 14, marginTop: 10 }}>
                            By clicking apply you agree to the{" "}
                            <Link
                                href="/promotion-terms"
                                target="_blank"
                            >Promotion Terms and Conditions
                            </Link>
                        </h3>
                    )}
                </>
            );
        }
    }
}

export default withStyles(styles)(PromoField);
